<template>
  <div>
    <p class="text-black text-center text-4xl lg:text-7xl leading-normal font-helvNeu">
      {{ trans('LOGO:Segment1') }}<span class="text-redAccentLight">{{ trans('LOGO:Segment2') }}</span>{{ trans('LOGO:dotDomain') }}
    </p>
    <p class="text-3xl lg:text-4xl text-center leading-tight mt-4 lg:mt-6 font-helvNeu">
      {{ trans('LOGO:Slogan1') }} <span class="text-redAccentLight">{{ trans('LOGO:Slogan2') }}</span> <span class="whitespace-nowrap">{{ trans('LOGO:Slogan3') }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "LandingLogo"
}
</script>