<template>
  <div
    class="w-full lg:w-page-width mx-auto flex min-h-088screen lg:min-h-screen justify-center items-start mb-3 lg:mb-6"
    :class="isChatOpenOnMobile()?'mt-0':'mt-16'"
  >
    <div class="w-full flex flex-col items-center">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
import {isChatOpenOnMobile} from "@/common/helpers/utils";

export default {
  name: "PageContainer",
	methods: {isChatOpenOnMobile}
}
</script>
